import request from '@/utils/request'

// 查询余额充值详情
export function queryRechargeDetails(data) {
    return request({
        url: '/api/user/recharge/details',
        method: 'post',
        data
    })
}



// 查询余额充值记录
export function queryRechargeList(data) {
    return request({
        url: '/api/user/recharge/list',
        method: 'post',
        data
    })
}


// 余额充值
export function create(data) {
    return request({
        url: '/api/user/recharge/create',
        method: 'post',
        data
    })
}

// 余额转账充值
export function zhuanzhangRecharge(data) {
    return request({
        url: '/api/user/recharge/zhuanZhang',
        method: 'post',
        data
    })
}

/**
 * 
 * @param 查询余额充值状态 
 * @returns 
 */
export function queryRechargeStatus(data) {
    return request({
        url: '/api/user/recharge/queryRechargeStatus',
        method: 'post',
        data
    })
}


// 加款卡充值
export function camiRecharge(data) {
    return request({
        url: '/api/user/recharge/camiRecharge',
        method: 'post',
        data
    })
}


// 查询充值活动详情
export function rechargeActivity(data) {
    return request({
        url: '/api/rechargeActivity/detail',
        method: 'post',
        data
    })
}



// 手工转账确认充值接口
export function confirmZhuanZhang(data) {
    return request({
        url: '/api/user/recharge/confirmZhuanZhang',
        method: 'post',
        data
    })
}